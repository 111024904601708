"use client";

import { FC } from "react";
import { TranslationScopes } from "@finbackoffice/enums";
import { IUserInterfaceTranslationResponse } from "@finbackoffice/clientbff-client";
import Header from "components/header/Header";
import Menu from "components/menu/Menu";

export const MainLayout: FC<{
    children: React.ReactNode;
    translations: Partial<Record<TranslationScopes, IUserInterfaceTranslationResponse>>;
}> = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Menu />
        </>
    );
};
