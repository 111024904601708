"use client";

import { FC, useEffect, useState } from "react";
import { TranslationScopes } from "@finbackoffice/enums";
import { IUserInterfaceTranslationResponse } from "@finbackoffice/clientbff-client";
import BonusesLayout from "components/layout/bonuses/BonusesLayout";
import { useSetI18nextResources } from "../../hooks";

export const BonusesLayoutContainer: FC<{
    children: React.ReactNode;
    translations: Partial<Record<TranslationScopes, IUserInterfaceTranslationResponse>>;
}> = ({ children, translations }) => {
    const [isReady, setIsReady] = useState(false);
    const { setTranslationResources } = useSetI18nextResources();

    useEffect(() => {
        setTranslationResources(translations);
        setIsReady(true);
    }, [translations, setTranslationResources]);

    if (!isReady) {
        return;
    }

    return <BonusesLayout>{children}</BonusesLayout>;
};
