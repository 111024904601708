"use client";

import { FC, useEffect } from "react";
import { usePathname } from "next/navigation";
import { TranslationScopes } from "@finbackoffice/enums";
import { IUserInterfaceTranslationResponse } from "@finbackoffice/clientbff-client";
import { RouteNames } from "utils/constants";
import { AccountCommonLayout } from "components/layout/common/AccountCommonLayout";
import { useSetI18nextResources } from "../../hooks";

export const AccountLayoutContainer: FC<{
    children: React.ReactNode;
    translations: Partial<Record<TranslationScopes, IUserInterfaceTranslationResponse>>;
}> = ({ children, translations }) => {
    const { setTranslationResources } = useSetI18nextResources();
    const pathname = usePathname();

    useEffect(() => {
        setTranslationResources(translations);
    }, [translations, setTranslationResources]);

    return (
        <>
            {!pathname?.includes(RouteNames.BETS) && (
                <AccountCommonLayout>{children}</AccountCommonLayout>
            )}
            {pathname?.includes(RouteNames.BETS) && children}
        </>
    );
};
