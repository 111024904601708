"use client";

import { EventStatus, UserStatus } from "@finbackoffice/enums";
import {
    IBetItemData,
    formatAppNameText,
    ProfileLayoutEnum,
    ISiteConfigLayouts,
    MenuButtonsEnum,
} from "@finbackoffice/fe-core";
import {
    AuthContext,
    BetSlipContextV2, // refactor
    ConfigContextV2, // refactor
    ModalsContextV2, // refactor
    SiteNotificationsContext,
    UserAccountContextV2, // refactor
    useRuntimeConfig,
    useSiteConfig,
    useTranslation,
    ChatContext,
    CrmContextV2, // refactor
    MultiBetBonusProviderV2,
    useSearchParamsQuery,
    formatSearchParamsToString,
    usePathnameWithLocal, // refactor
} from "@finbackoffice/site-core";
import classNames from "classnames";
import dynamic from "next/dynamic";
import { useRouter } from "next/navigation";
import {
    FC,
    RefObject,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Button from "components/base/button/Button";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Modal, { IModalForwardRefProps } from "components/base/modal/Modal";
import { Svg } from "components/base/svg/Svg";
import { InitialDataContext, NotificationContext } from "contexts";
import { useLiveSports, useSportResults } from "hooks";
import { validationRules } from "utils/betValidation";
import { ModalTypes, RouteNames, RouterQuery } from "utils/constants";
import PreloadComponent from "components/base/preload-component/PreloadComponent";
import Account from "components/account/Account";
import Translate from "../base/translate/Translate";
import Compact from "./layouts/compact/Compact";
import styles from "./menu.module.sass";

const LoginPanel = dynamic(() => import("./login-panel/LoginPanel"), {
    loading: () => <PreloadComponent style={{ height: 422, margin: 0, border: 0 }} />,
    ssr: false,
});

const Verification = dynamic(() => import("components/account/verification/Verification"), {
    ssr: false,
});

const BetSlip = dynamic(() => import("../pages/sport/betslip/BetSlip"), {
    ssr: false,
});

const FloatingBetslip = dynamic(() => import("./floating-betslip/FloatingBetslip"), {
    ssr: false,
});

const Game = dynamic(() => import("components/pages/sport/base/game/Game"), {
    ssr: false,
});

type MenuScopeType = "default" | "sport" | "casino";

type IMenuContext = {
    betProcessing: boolean;
    betCountChange: boolean;
    onBetSlipButtonClickHandler: () => void;
    handleLiveButtonClick: () => void;
    handleSportButtonClick: () => void;
    hasBetSlipError: boolean;
    liveButtonForceActive: boolean;
    currentModal: string | null;
    betItems: IBetItemData[];
    menuScope: MenuScopeType;
};

export const MenuContext = createContext<IMenuContext>(null as any);

const Menu: FC = () => {
    useLiveSports(); // refactor
    useSportResults(); // refactor
    const { t } = useTranslation();
    const router = useRouter();
    const { pathname, pathnameValues } = usePathnameWithLocal();
    const { query } = useSearchParamsQuery();
    const { gameId, sport, tournamentId, type: modalType, liveGameRoutes } = query;
    const { loadNotifications } = useContext(SiteNotificationsContext);
    const { userProfileStatus, loadProfile, isKycVerified, isEmailVerified, isPhoneVerified } =
        useContext(UserAccountContextV2); // refactor
    const { isUserLoggedIn } = useContext(AuthContext);
    const { unreadMessages, chatStats } = useContext(ChatContext);
    const { unreadMessagesCount } = useContext(CrmContextV2);
    const { currentModal, setCurrentModal, loginModalRef, accountModalRef } =
        useContext(ModalsContextV2); // refactor
    const { betItems, betProcessing, betSlipError } = useContext(BetSlipContextV2);
    const [betCountChange, setBetCountChange] = useState(false);
    const { notice } = useContext(NotificationContext);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const verificationModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const betSlipModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const gameModalRef: RefObject<IModalForwardRefProps> = useRef(null);
    const { currentGameStatus, setCurrentGameStatus, activeSportGame, setActiveSportGame } =
        useContext(InitialDataContext);
    const { siteRegistrationConfig, isSportMultiBetBonusesEnabled } = useContext(ConfigContextV2);
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const [liveButtonForceActive, setLiveButtonForceActive] = useState<boolean>(
        currentGameStatus === EventStatus.Live,
    );

    const menuScope = useMemo(() => {
        const chunk = pathnameValues[0] as MenuScopeType;
        switch (chunk) {
            case "casino":
            case "sport":
                return chunk as MenuScopeType;
            default:
                return "default";
        }
    }, [pathnameValues]);

    useEffect(() => {
        if (modalType === RouterQuery.Login && loginModalRef.current) {
            loginModalRef.current.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalType]);

    useEffect(() => {
        if (liveButtonForceActive) {
            setLiveButtonForceActive(false);
        }

        if (
            [
                ModalTypes.BET_SLIP,
                ModalTypes.ACCOUNT,
                ModalTypes.LOGIN,
                ModalTypes.SIGNUP,
                ModalTypes.SETTINGS,
            ].includes(currentModal || "")
        ) {
            setCurrentModal(null);
        } else if (gameModalRef.current?.isOpened) {
            gameModalRef.current.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        if (currentGameStatus === EventStatus.Live) {
            setLiveButtonForceActive(true);
            setCurrentGameStatus(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGameStatus]);

    useEffect(() => {
        if (activeSportGame.gameId && !gameModalRef.current?.isOpened) {
            gameModalRef.current?.open();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSportGame.gameId]);

    useEffect(() => {
        if (betItems.length) {
            setBetCountChange(true);

            setTimeout(() => {
                setBetCountChange(false);
            }, 400);
        }
    }, [betItems.length]);

    useEffect(() => {
        if (userProfileStatus) {
            if (modalType === ModalTypes.ACCOUNT) {
                accountModalRef.current?.open();
            } else if (
                (siteRegistrationConfig?.require_email_verification && !isEmailVerified) ||
                (siteRegistrationConfig?.require_phone_verification && !isPhoneVerified)
            ) {
                verificationModalRef.current?.open();
            } else if (siteRegistrationConfig?.require_id_verification && !isKycVerified) {
                notice(
                    {
                        type: "error",
                        title: t("verification_subHeading"),
                        message: t("verification_id_description"),
                    },
                    "modal",
                );
            }

            loadNotifications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileStatus]);

    const onProfileClick = useCallback(() => {
        if (pathname?.includes(`/${RouteNames.ACCOUNT}`)) {
            router.push(`/${RouteNames.SPORT}/${RouteNames.LIVE}`);
        } else if (currentModal === ModalTypes.ACCOUNT) {
            setCurrentModal(null);
        } else {
            loadProfile();
            accountModalRef.current?.open();
        }
    }, [pathname, currentModal, router, setCurrentModal, loadProfile, accountModalRef]);

    const onBetSlipButtonClickHandler = useCallback(() => {
        if (currentModal === ModalTypes.BET_SLIP) {
            setCurrentModal(null);
        } else {
            betSlipModalRef.current?.open();
        }
    }, [currentModal, setCurrentModal]);

    const handleLiveButtonClick = useCallback(() => {
        const pathToLive = `/${RouteNames.SPORT}/${RouteNames.LIVE}`;
        const pathToCalendar = `/${RouteNames.SPORT}/${RouteNames.CALENDAR}`;
        const isLiveView = pathname?.includes(pathToLive);
        const isCalendarView = pathname?.includes(pathToCalendar);
        if (!(isLiveView || isCalendarView)) {
            router.push(pathToLive);
        } else if (isLiveView && !!liveGameRoutes) {
            router.push(`${pathToLive}/${liveGameRoutes[0]}`);
        }
    }, [pathname, liveGameRoutes, router]);

    const handleSportButtonClick = useCallback(() => {
        const pathToPrematch = `/${RouteNames.SPORT}/${RouteNames.PREMATCH}`;
        const isPrematchView = pathname?.includes(pathToPrematch);
        if (!isPrematchView) {
            router.push(`${pathToPrematch}/${RouteNames.TOP}`);
        } else if (!!tournamentId || !!gameId) {
            router.push(`${pathToPrematch}/${RouteNames.ALL}/${sport}`);
        }
    }, [pathname, sport, gameId, tournamentId, router]);

    useEffect(() => {
        if (!gameId && activeSportGame.gameId) {
            gameModalRef.current?.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameId]);

    const hasBetSlipError = useMemo(
        () =>
            validationRules.doesAnyBetHaveError(betItems, betSlipError) ||
            !!validationRules.getRemovableBets(betItems).length,
        [betSlipError, betItems],
    );

    const renderLoginButton = useMemo(() => {
        const onLoginClick = () => {
            if (currentModal === ModalTypes.LOGIN) {
                setCurrentModal(null);
            } else {
                loginModalRef.current?.open();
            }
        };
        return (
            <>
                <Button
                    type="button"
                    variant="custom"
                    onClick={onLoginClick}
                    className={classNames(
                        styles.login,
                        currentModal === ModalTypes.LOGIN && styles.active,
                    )}
                    data-testid="header-login-button">
                    <Translate tid="menu_login" />
                    <Svg
                        src={`/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/desktop/icons/header-login-icon.svg`}
                        wrapper="span"
                        className="svg-icon"
                    />
                </Button>
                <ErrorBoundary name={LoginPanel.name}>
                    <Modal
                        ref={loginModalRef}
                        styleClass={styles.loginModalCompact}
                        type={ModalTypes.LOGIN}
                        maskClosable={false}
                        animateVariant="bottom">
                        <LoginPanel />
                    </Modal>
                </ErrorBoundary>
            </>
        );
    }, [currentModal, COMMON_SITE_CONFIGS.appName, loginModalRef, setCurrentModal]);

    const renderProfileButton = useMemo(() => {
        const unreadMessagesMemo =
            unreadMessages.length + (chatStats?.unread_message ?? 0) + unreadMessagesCount;
        return (
            <>
                <Button
                    type="button"
                    variant="custom"
                    onClick={onProfileClick}
                    className={classNames(
                        styles.profile,
                        siteLayoutsConfig.profile.type === ProfileLayoutEnum.Default &&
                            styles.profileDefault,
                        (currentModal === ModalTypes.ACCOUNT ||
                            pathname?.includes(`/${RouteNames.ACCOUNT}`)) &&
                            styles.active,
                    )}
                    data-testid="open-user-panel-button">
                    <Translate tid="menu_profile" />
                    <span>
                        <Svg
                            src={`/${formatAppNameText(
                                COMMON_SITE_CONFIGS.appName,
                            )}/desktop/icons/header-login-icon.svg`}
                            wrapper="span"
                            className="svg-icon"
                        />
                        {Boolean(unreadMessagesMemo) && (
                            <span className={styles.unreadMsgsCount}>{unreadMessagesMemo}</span>
                        )}
                        {userProfileStatus && userProfileStatus !== UserStatus.Registered && (
                            <Svg
                                src="/common/desktop/base-icons/question-icon.svg"
                                wrapper="span"
                                className={styles.verificationReminder}
                            />
                        )}
                    </span>
                </Button>

                <ErrorBoundary name={Account.name}>
                    <Modal
                        ref={accountModalRef}
                        styleClass={classNames(
                            siteLayoutsConfig.profile.type === ProfileLayoutEnum.Compact &&
                                styles.accountModalCompact,
                        )}
                        type={ModalTypes.ACCOUNT}
                        animateVariant={
                            siteLayoutsConfig.profile.type === ProfileLayoutEnum.Default
                                ? "right"
                                : "bottom"
                        }>
                        <Account />
                    </Modal>
                </ErrorBoundary>

                <Modal
                    ref={verificationModalRef}
                    styleClass={styles.userVerificationModal}
                    type={ModalTypes.VERIFICATION}
                    closeButton
                    maskClosable={false}
                    animateVariant="opacity">
                    <Verification />
                </Modal>
            </>
        );
    }, [
        onProfileClick,
        siteLayoutsConfig.profile.type,
        currentModal,
        pathname,
        COMMON_SITE_CONFIGS.appName,
        userProfileStatus,
        accountModalRef,
        unreadMessages.length,
        chatStats,
        unreadMessagesCount,
    ]);

    const renderFloatingBetslip = useMemo(() => {
        if (
            COMMON_SITE_CONFIGS.betslip.floatingBetslip &&
            siteLayoutsConfig.menu[menuScope].includes(MenuButtonsEnum.BetSlip)
        ) {
            return <FloatingBetslip />;
        }
        return null;
    }, [COMMON_SITE_CONFIGS.betslip.floatingBetslip, menuScope, siteLayoutsConfig.menu]);

    const renderModals = useMemo(
        () => (
            <>
                <Modal
                    ref={betSlipModalRef}
                    type={ModalTypes.BET_SLIP}
                    animateVariant="bottom"
                    styleClass={styles.betslipModal}>
                    <BetSlip />
                </Modal>
                <Modal
                    ref={gameModalRef}
                    type={ModalTypes.GAME}
                    animateVariant="bottom"
                    closeButton
                    priority
                    styleClass={styles.liveGameModal}
                    onClose={(e) => {
                        if (e) {
                            const q = { ...query };
                            delete q.gameId;
                            router.replace(`${pathname}?${formatSearchParamsToString(q)}`);
                        }

                        setActiveSportGame({
                            tournamentGames: null,
                            gameId: null,
                        });
                    }}>
                    <div className={styles.liveGameContainer}>
                        {activeSportGame.gameId && (
                            <Game id={parseInt(activeSportGame.gameId)} variant="modal" />
                        )}
                    </div>
                </Modal>
            </>
        ),
        [activeSportGame.gameId, pathname, query, router, setActiveSportGame],
    );

    const value = useMemo(
        () => ({
            betProcessing,
            betCountChange,
            onBetSlipButtonClickHandler,
            handleLiveButtonClick,
            handleSportButtonClick,
            hasBetSlipError,
            liveButtonForceActive,
            currentModal,
            betItems,
            menuScope,
        }),
        [
            betProcessing,
            betCountChange,
            onBetSlipButtonClickHandler,
            handleLiveButtonClick,
            handleSportButtonClick,
            hasBetSlipError,
            liveButtonForceActive,
            currentModal,
            betItems,
            menuScope,
        ],
    );

    const renderContent = useMemo(
        () => (
            <>
                {renderFloatingBetslip}
                <div className={styles.bottomMenuContainer}>
                    <div className={styles.menu}>
                        <MenuContext.Provider value={value}>
                            <Compact />
                        </MenuContext.Provider>

                        {isUserLoggedIn ? renderProfileButton : renderLoginButton}
                    </div>
                </div>
                {renderModals}
            </>
        ),
        [
            isUserLoggedIn,
            renderFloatingBetslip,
            renderLoginButton,
            renderModals,
            renderProfileButton,
            value,
        ],
    );

    return (
        <ErrorBoundary name={Menu.name}>
            {isSportMultiBetBonusesEnabled ? (
                <MultiBetBonusProviderV2>{renderContent}</MultiBetBonusProviderV2>
            ) : (
                renderContent
            )}
        </ErrorBoundary>
    );
};
export default Menu;
