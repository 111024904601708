"use client";

import { FC, useContext } from "react";
import { AuthContext, RuntimeConfigContext } from "@finbackoffice/site-core";
import CookiesConsent from "components/cookie-consent/CookiesConsent";
import FloatingLogin from "components/floating-login/FloatingLogin";

export const MainLayoutComponents: FC<{
    translations: Record<string, any>;
}> = () => {
    const { isUserLoggedIn } = useContext(AuthContext);
    const runtimeConfig = useContext(RuntimeConfigContext);

    return (
        <>
            {runtimeConfig.COMMON_SITE_CONFIGS.cookiesConsentPopup && <CookiesConsent />}
            {runtimeConfig.COMMON_SITE_CONFIGS.login.floatingLogin && !isUserLoggedIn && (
                <FloatingLogin />
            )}
        </>
    );
};
