"use client";

import { FC, useEffect, useMemo } from "react";
import { useParams } from "next/navigation";
import { TranslationScopes } from "@finbackoffice/enums";
import { IUserInterfaceTranslationResponse } from "@finbackoffice/clientbff-client";
import { useSetI18nextResources } from "../../hooks";
import ResultsLayout from "components/layout/sportsbook/results/ResultsLayout";
import { CommonLayout } from "components/layout/common/CommonLayout";

export const SportResultsLayoutContainer: FC<{
    children: React.ReactNode;
    translations: Partial<Record<TranslationScopes, IUserInterfaceTranslationResponse>>;
}> = ({ children, translations }) => {
    const { setTranslationResources } = useSetI18nextResources();
    const params = useParams();

    useEffect(() => {
        setTranslationResources(translations);
    }, [translations, setTranslationResources]);

    const getLayout = useMemo(() => {
        if (params?.resultsGameRoutes) {
            return <CommonLayout>{children}</CommonLayout>;
        } else {
            return <ResultsLayout>{children}</ResultsLayout>;
        }
    }, [params, children]);

    return <>{getLayout}</>;
};
