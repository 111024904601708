"use client";

import { FC, useCallback, useContext } from "react";
import { CasinoGameTag, HrefBehavior, PopupTags, TranslationScopes } from "@finbackoffice/enums";
import { PopupBannersContext, useRuntimeConfig, useSiteConfig } from "@finbackoffice/site-core";
import {
    formatAppNameText,
    ILayoutsSubHeaderConfig,
    ISiteConfigLayouts,
} from "@finbackoffice/fe-core";
import Button from "components/base/button/Button";
import { CasinoToolsContext } from "contexts";
import ActiveLink from "components/base/active-link/ActiveLink";
import { Svg } from "components/base/svg/Svg";
import { useLoadI18nextResource } from "hooks";
import Translate from "components/base/translate/Translate";
import styles from "./sub-header.module.sass";

const SubHeader: FC = () => {
    const { isTranslationsReady } = useLoadI18nextResource(TranslationScopes.SubHeader);
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const { popupScope } = useContext(PopupBannersContext);
    const { handlePlayNow } = useContext(CasinoToolsContext);
    const isGame = useCallback(
        (href_behavior: HrefBehavior) =>
            [HrefBehavior.GameSlot, HrefBehavior.GameLiveCasino].includes(href_behavior),
        [],
    );

    const isLink = useCallback(
        (href_behavior: HrefBehavior) =>
            [HrefBehavior.External, HrefBehavior.Relative].includes(href_behavior),
        [],
    );

    const onButtonClick = useCallback(
        (link: ILayoutsSubHeaderConfig) => {
            const href = link.href;

            const isSlots = link.href_behavior === HrefBehavior.GameSlot;
            const gameId = href ? `${href}-mobile` : "";

            handlePlayNow(gameId, isSlots);
        },
        [handlePlayNow],
    );

    const renderFlag = useCallback((link: ILayoutsSubHeaderConfig) => {
        switch (true) {
            case link.tags.includes(CasinoGameTag.New):
                return <span className={styles.new}>new</span>;
            case link.tags.includes(CasinoGameTag.Featured):
                return <span className={styles.live}>live</span>;
            case link.tags.includes(CasinoGameTag.Hot):
                return (
                    <Svg
                        key="new"
                        src={`/common/casino/filters/hot.svg`}
                        wrapper="span"
                        className={styles.hot}
                    />
                );
            case link.tags.includes(CasinoGameTag.Top):
                return (
                    <Svg
                        key="new"
                        src={`/common/casino/filters/top.svg`}
                        wrapper="span"
                        className={styles.top}
                    />
                );
            case link.tags.includes(CasinoGameTag.Popular):
                return (
                    <Svg
                        key="new"
                        src={`/common/casino/filters/popular.svg`}
                        wrapper="span"
                        className={styles.popular}
                    />
                );
            default:
                return null;
        }
    }, []);

    const renderContent = useCallback(
        (link: ILayoutsSubHeaderConfig) => (
            <>
                {Boolean(link.tags.length) && renderFlag(link)}
                {link.icon_filename && (
                    <Svg
                        src={`/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/common/sub-header/${link.icon_filename}`}
                        wrapper="span"
                        className="svg-icon"
                    />
                )}
                <Translate tid={`${link.id}_title`} namespace={TranslationScopes.SubHeader} />
            </>
        ),
        [COMMON_SITE_CONFIGS.appName, renderFlag],
    );

    const renderLink = (link: ILayoutsSubHeaderConfig) => {
        if (isLink(link.href_behavior) && link.href) {
            if (link.href_behavior === HrefBehavior.Relative) {
                return (
                    <ActiveLink
                        key={link.id}
                        // onClick={linkClickHandler}
                        href={link.href}
                        activeClassName={styles.active}
                        className="banner-item">
                        {renderContent(link)}
                    </ActiveLink>
                );
            }
        } else if (isGame(link.href_behavior)) {
            return (
                <Button
                    key={link.id}
                    type="button"
                    onClick={() => onButtonClick(link)}
                    className="banner-item">
                    {renderContent(link)}
                </Button>
            );
        }
        return null;
    };

    if (!isTranslationsReady) {
        return null;
    }

    return (
        <div className={styles.subHeader}>
            <nav>
                {siteLayoutsConfig.subHeader.config
                    .filter((link) =>
                        link.scopes.some(
                            (scope) => popupScope?.includes(scope) || scope === PopupTags.Allpages,
                        ),
                    )
                    .map(renderLink)}
            </nav>
        </div>
    );
};

export default SubHeader;
