"use client";

import { FC, useEffect, useMemo } from "react";
import { useParams } from "next/navigation";
import { TranslationScopes } from "@finbackoffice/enums";
import { IUserInterfaceTranslationResponse } from "@finbackoffice/clientbff-client";
import { useSetI18nextResources } from "../../hooks";
import { CommonLayout } from "components/layout/common/CommonLayout";
import { SportLiveLayout } from "./SportLiveLayout";

export const SportLiveLayoutContainer: FC<{
    children: React.ReactNode;
    translations: Partial<Record<TranslationScopes, IUserInterfaceTranslationResponse>>;
}> = ({ children, translations }) => {
    const { setTranslationResources } = useSetI18nextResources();
    const params = useParams();

    useEffect(() => {
        setTranslationResources(translations);
    }, [translations, setTranslationResources]);

    const getLayout = useMemo(() => {
        if (params?.liveGameRoutes && params?.liveGameRoutes?.length > 0) {
            return <CommonLayout>{children}</CommonLayout>;
        } else {
            return <SportLiveLayout>{children}</SportLiveLayout>;
        }
    }, [params, children]);

    return <>{getLayout}</>;
};
